import emailjs from "@emailjs/browser";
import { keyframes } from "@emotion/react";
import { MDBCheckbox } from "mdb-react-ui-kit";
import { RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Popup from "reactjs-popup";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../../components/footer/Footer";
import Gallery from "../../../components/gallery/gallery";
import PcServiceGallery from "../../../components/gallery/pc_services_gallery";
import ServiceGallery from "../../../components/gallery/services_gallery";
import Header from "../../../components/header/header";
import ReviewGallery from "../../../components/reviews/ReviewGallery";

import PcGallery from "../../../components/gallery/pc_gallery";
import ReviewGalleryDesktop from "../../../components/reviews/ReviewGalleryDesktop";
import "../../../components/service_template/styles/services_styles.css";

import { Reveal } from "react-awesome-reveal";

import { faClose, faPhone } from "@fortawesome/free-solid-svg-icons";

const pavelGallery = require("../../../assets/pavel_gallery.webp");
const pavelGalleryPc = require("../../../assets/pavel_pc.webp");

const mainService = require("../../../assets/service_pc.webp");

const doctorOnePc = require("../../../assets/1.webp");
const doctorTwoPc = require("../../../assets/karen_pc.webp");
const doctorThreePc = require("../../../assets/3.webp");
const doctorFourPc = require("../../../assets/islam_pc.webp");
const doctorFivePc = require("../../../assets/elena_pc.webp");
const doctorSixPc = require("../../../assets/tatyana_pc.webp");
const doctorSevenPc = require("../../../assets/marina_pc.webp");
const doctorEightPc = require("../../../assets/karen_pc.webp");
const doctorNinePc = require("../../../assets/karen_pc.webp");
const doctorTenPc = require("../../../assets/orthoped_mob.webp");
const doctorElevenPc = require("../../../assets/sadiga.webp");

const doctorOneMob = require("../../../assets/01.webp");
const doctorTwoMob = require("../../../assets/marina_mob.webp");
const doctorThreeMob = require("../../../assets/03.webp");
const doctorFourMob = require("../../../assets/islam_mob.webp");
const doctorFiveMob = require("../../../assets/elena_mob.webp");
const doctorSixMob = require("../../../assets/tatyana_mob.webp");
const doctorSevenMob = require("../../../assets/marina_mob.webp");
const doctorEightMob = require("../../../assets/karen_mob.webp");
const doctorNineMob = require("../../../assets/marina_mob.webp");
const doctorTenMob = require("../../../assets/orthoped_mob.webp");
const doctorElevenMob = require("../../../assets/marina_mob.webp");

const modalImage = require("../../../assets/example_modal.webp");
const logoMobile = require("../../../assets/logo_mob.svg").default;

const serviceOnePc = require("../../../assets/service_1.webp");
const serviceTwoPc = require("../../../assets/service_2.webp");
const ServiceThreePc = require("../../../assets/service_3.webp");
const ServiceFourPc = require("../../../assets/service_4.webp");

const serviceOneMob = require("../../../assets/service_mob_01.webp");
const serviceTwoMob = require("../../../assets/service_mob_02.webp");
const serviceThreeMob = require("../../../assets/service_mob_03.webp");
const serviceFourMob = require("../../../assets/service_mob_04.webp");

const prostheticsPc = require("../../../assets/prosthetics_pc.webp");

const ImplantsPage = () => {
  const [thankYou, setThankYou] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuPcOpen, setIsMenuPcOpen] = useState(false);

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [bookDate, setBookDate] = useState("");
  const [bookTime, setBookTime] = useState("");
  const [visitGoal, setVisitGoal] = useState("");
  const [comment, setComment] = useState("");

  const form: RefObject<HTMLDivElement> = useRef(null);

  function sendEmail(e: any) {
    e.preventDefault();
    setFullName("");
    setPhoneNumber("");
    setServiceName("");
    setDoctorName("");
    setServiceName("");

    emailjs
      .sendForm(
        "service_kwh5orp",
        "template_5kdc5wu",
        e.target,
        "b-K7bdT7JW4cqcN4y"
      )
      .then((res) => {
        setThankYou(true);
        console.log("SUCCESS");
      })
      .catch((err) => console.log(err));
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const togglePcMenu = () => {
    setIsMenuPcOpen(!isMenuPcOpen);
  };

  const customAnimation = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0px, 0px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000, 0, 0);
  }
`;
  const [open, setOpen] = useState(false);

  const phoneForm = useRef<HTMLFormElement>(null);

  function openPopupWindow() {
    setOpen(true);
  }

  function sendPhoneRequest(e: any) {
    e.preventDefault();
    setFullName("");
    setPhoneNumber("");

    emailjs
      .sendForm(
        "service_kwh5orp",
        "template_rgnaux5",
        e.target,
        "b-K7bdT7JW4cqcN4y"
      )
      .then((res) => {
        setThankYou(true);
        console.log("SUCCESS");
      })
      .catch((err) => console.log(err));
  }

  const openModal = () => {
    console.log("Opening modal");
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setThankYou(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => {};
  }, []);

  return (
    <div className="div">
      {isLoading ? (
        <Reveal keyframes={customAnimation} delay={1000}>
          <div className={`loader ${isLoading ? "" : "hidden"}`}>
            <div className="loader-container">
              <img className="logo" src={logoMobile} alt="logo"></img>
              <h1 className="loading-heading">
                Клиника Эстетической Стоматологии
              </h1>
            </div>
          </div>
        </Reveal>
      ) : (
        <div className="screen">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              Имплантация зубов под ключ в Ивантеевке | Стоматологии Dental Home
            </title>
            <meta
              property="og:title"
              content="Имплантация зубов под ключ в Ивантеевке | Стоматологии Dental Home"
            ></meta>
            <meta
              property="og:description"
              content="Восстановите утраченные зубы с помощью имплантации под ключ в Ивантеевке. Запишитесь на прием в нашу сеть стоматологий уже сегодня!"
            ></meta>
            <meta
              name="description"
              content="Восстановите утраченные зубы с помощью имплантации под ключ в Ивантеевке. Запишитесь на прием в нашу сеть стоматологий уже сегодня!"
            ></meta>
          </Helmet>
          <main className="services-screen-mobile">
            <div className="content">
              <div className="header-container" style={{ width: "100%" }}>
                <Header
                  isMenuOpen={isMenuOpen}
                  openModal={openModal}
                  toggleMenu={toggleMenu}
                ></Header>
              </div>
              <div className="tablet" style={{ width: "100%" }}>
                <Header
                  isMenuPcOpen={isMenuPcOpen}
                  openModal={openModal}
                  togglePcMenu={togglePcMenu}
                ></Header>
              </div>
              <div className="heading-container">
                <div className="bread-dots">
                  <Link to="/" className="link">
                    Главная
                  </Link>
                  <div className="circle"></div>
                  <Link to="/#services" className="link">
                    Услуги
                  </Link>
                  <div className="circle"></div>
                  <Link to="/services/prosthetics" className="link active">
                    Протезирование
                  </Link>
                </div>
                <h1 className="heading">
                  Имплантация зубов и челюстей в Ивантеевке
                </h1>
                <button className="golden-button" onClick={openModal}>
                  <span className="text">Записаться</span>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="icon"
                  ></FontAwesomeIcon>
                </button>
              </div>
              <div className="information-container">
                <div className="divider-container">
                  <div className="divider"></div>
                  <span className="text">Об услуге</span>
                </div>
                <section className="information">
                  <h3 className="information-heading">
                    Имплантация под ключ в Dental Home
                  </h3>
                  <p className="information-paragraph">
                    Имплантация зуба под ключ в Ивантеевке – это современный и
                    эффективный способ восстановления утраченного зуба. Наша
                    клиника предлагает качественные услуги по вставке зубных
                    имплантов, которые помогут вернуть вам уверенность в своей
                    улыбке.
                    <br />
                    <br />
                    Мы предлагаем различные виды зубных имплантов по доступной
                    цене. Наши специалисты помогут вам выбрать наиболее
                    подходящий вариант, который удовлетворит все ваши
                    потребности.
                    <br />
                    <br />
                    Установка импланта зуба под ключ – это процедура, которая
                    проводится в несколько этапов. Сначала наш специалист
                    проводит осмотр и консультацию, после чего планируется
                    хирургическая операция по установке импланта. Затем
                    происходит период ожидания, когда имплант должен
                    интегрироваться с костью. После этого проводится этап
                    протезирования, когда на имплант устанавливается коронка.
                    <br />
                    <br />
                    Установка импланта зуба под ключ цена зависит от сложности
                    процедуры и используемых материалов. Но мы гарантируем, что
                    наши цены будут доступными и адекватными.
                    <br />
                    <br />
                    Где лучше ставить импланты зубов? Наша клиника Dental Home
                    находится в Ивантеевке и предлагает качественные услуги по
                    установке имплантов зубов в Московской области. Мы
                    используем только самые современные технологии и материалы,
                    чтобы обеспечить максимальный результат.
                    <br />
                    <br />
                    Имплант зуба сразу – это возможность получить новый зуб за
                    один день. Наша клиника предлагает услугу установки импланта
                    зуба сразу после удаления старого зуба. Это позволяет
                    избежать периода ожидания и получить новый зуб максимально
                    быстро.
                    <br />
                    <br />
                    Стоимость импланта зуба – это важный вопрос для многих
                    пациентов. Но мы гарантируем, что наши цены на установку
                    импланта зуба будут доступными и адекватными. Мы предлагаем
                    качественные услуги по установке имплантов зубов по разумной
                    цене.
                    <br />
                    <br />
                    Зубные импланты цена – это важный фактор при выборе клиники
                    для установки имплантов зубов. Наша клиника предлагает
                    качественные услуги по установке имплантов зубов по
                    доступной цене. Мы гарантируем максимальный результат и
                    безболезненную процедуру.
                    <br />
                    <br />
                    Установка импланта зуба – это возможность получить новый
                    зуб, который будет выглядеть и функционировать как
                    настоящий. Наша клиника предлагает качественные услуги по
                    установке импланта зуба под ключ, которые помогут вам
                    вернуть уверенность в своей улыбке. Не откладывайте поход к
                    стоматологу на потом! Запишитесь на консультацию по
                    установке импланта зуба уже сегодня и получите
                    профессиональную помощь в достижении желаемого результата.
                  </p>
                </section>
                <div className="divider"></div>
                <div className="plate">
                  <section className="tab-one">
                    <h5 className="plate-heading">
                      Протезирование на имплантах
                    </h5>
                    <span className="text">
                      <a
                        href="https://dental-home.ru/services/prosthetics"
                        className="white"
                      >
                        Протезирование зуба
                      </a>{" "}
                      с использованием имплантата временной коронкой + временный
                      абатмент <br /> <br /> (ИМ-А16.07.006.001)
                    </span>
                    <span className="price">От 8.950₽</span>
                  </section>
                  <section className="tab-two">
                    <span className="text">
                      Единица промежуточной части временного мостовидного
                      протеза с опорой на имплантах <br /> <br />{" "}
                      (ИМ-А16.07.006.002)
                    </span>
                    <span className="price">От 4.750₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование зуба с использованием имплантата коронкой
                      металлокерамической класса "Эстетик" (трансокклюзионная
                      фиксация) <br /> <br /> (ИМ-А16.07.006.004 )
                    </span>
                    <span className="price">От 32.550₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование зуба с использованием имплантата коронкой
                      металлокерамической класса "стандарт" (цементная фиксация)
                      на индивидуальном абатменте <br /> <br />{" "}
                      (ИМ-А16.07.006.005)
                    </span>
                    <span className="price">От 26.250₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование зуба с использованием имплантата коронкой
                      металлокерамической класса "Премиум" (цементная фиксация)
                      на индивидуальном абатменте <br /> <br />{" "}
                      (ИМ-А16.07.006.006)
                    </span>
                    <span className="price">От 32.550₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Единица промежуточной части мостовидного протеза класса
                      "Стандарт" (металлокерамика) с опорой на имплантах <br />{" "}
                      <br /> (ИМ-А16.07.006.007)
                    </span>
                    <span className="price">От 17.850₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Единица промежуточной части мостовидного протеза класса
                      "Премиум" (металлокерамика) с опорой на имплантах <br />{" "}
                      <br /> (ИМ-А16.07.006.008)
                    </span>
                    <span className="price">От 22.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование зуба с использованием имплантата коронокой
                      из диоксида циркония класса "Стандарт"(трансокклюзионная
                      фиксация) <br /> <br /> (ИМ-А16.07.006.009)
                    </span>
                    <span className="price">От 34.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование зуба с использованием имплантата коронокой
                      из диоксида циркония класса "Премиум"(трансокклюзионная
                      фиксация) <br /> <br /> (ИМ-А16.07.006.010)
                    </span>
                    <span className="price">От 39.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      {" "}
                      Протезирование зуба с использованием имплантата коронокой
                      из диоксида циркония класса "Стандарт"(трансокклюзионная
                      фиксация) на индивидуальном абатмене <br /> <br />{" "}
                      (ИМ-А16.07.006.011)
                    </span>
                    <span className="price">От 41.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      {" "}
                      Протезирование зуба с использованием имплантата коронокой
                      из диоксида циркония класса "Премиум"(трансокклюзионная
                      фиксация) на индивидуальном абатмене <br /> <br />{" "}
                      (ИМ-А16.07.006.012)
                    </span>
                    <span className="price">От 47.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Единица промежуточной части мостовидного протеза из
                      диоксида циркония класса "Стандарт" с опорой на
                      имплантатах <br /> <br /> (ИМ-А16.07.006.013)
                    </span>
                    <span className="price">От 23.000₽</span>
                  </section>
                  <section className="tab-three"></section>
                  <section className="tab-three">
                    <span className="text">
                      Единица промежуточной части мостовидного протеза из
                      диоксида циркония класса "Премиум" с опорой на имплантатах{" "}
                      <br /> <br /> (ИМ-А16.07.006.014)
                    </span>
                    <span className="price">От 27.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование зуба с использованием имплантата коронкой
                      E-max на титановом основании класса "Премиум"
                      (трансокклюзионная фиксация) <br /> <br />{" "}
                      (ИМ-А16.07.006.015)
                    </span>
                    <span className="price">От 31.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Индивидуальный абатмент из диоксида циркония <br /> <br />{" "}
                      (ИМ-А16.07.006.016)
                    </span>
                    <span className="price">От 15.800₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование полным условно-съемным протезом с опорой на
                      имплантатах (балочная конструкция) мультиюниты
                      оплачиваются отдельно <br /> <br /> (ИМ-А16.07.006.017)
                    </span>
                    <span className="price">От 194.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование полным несъемным протезом с опорой на
                      имплантатах на мультиюнитах (мультиюниты оплачиваются
                      отдельно) <br /> <br /> (ИМ-А16.07.006.018)
                    </span>
                    <span className="price">От 194.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Стоимость мультиюнита (1 единица) <br /> <br />{" "}
                      (ИМ-А16.07.006.019 )
                    </span>
                    <span className="price">От 21.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование полным съемным протезом на имплантатах с
                      замковым креплением (люкаторная система замков) <br />{" "}
                      <br /> (ИМ-А16.07.006.020)
                    </span>
                    <span className="price">От 126.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Протезирование полным съемным протезом на имплантатах на
                      аттачментах <br /> <br /> (ИМ-А16.07.006.021)
                    </span>
                    <span className="price">От 99.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Временный несъемный протез на имплантатах <br /> <br />{" "}
                      (ИМ-А16.07.006.22)
                    </span>
                    <span className="price">От 84.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Замена винта трансокклюзионной фиксации
                      <br /> <br /> (ИМ-А23.07.002.04)
                    </span>
                    <span className="price last">От 550₽</span>
                  </section>
                </div>
                <div
                  className="plate"
                  style={{ marginTop: "clamp(16px,7.47648vw,64px)" }}
                >
                  <section className="tab-one">
                    <h5 className="plate-heading">
                      Внутрикостная дентальная имплантация системой
                    </h5>
                    <span className="text">
                      Имплантация системой Dentium SuperLine (Ю.Корея)
                      <br /> <br /> (ИМ-А16.07.054.001)
                    </span>
                    <span className="price">От 41.000₽</span>
                  </section>
                  <section className="tab-two">
                    <span className="text">
                      Имплантация системой NeoDent GM (Бразилия) <br /> <br />{" "}
                      (ИМ-А16.07.054.002)
                    </span>
                    <span className="price">От 41.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Имплантация системой Nobel Biocare (Швейцария) <br />{" "}
                      <br /> (ИМ-А16.07.054.003)
                    </span>
                    <span className="price">От 73.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Миниимплант (ортовинт) <br /> <br /> (ИМ-А16.07.054.004){" "}
                    </span>
                    <span className="price last">От 12.600₽</span>
                  </section>
                </div>
                <div
                  className="plate"
                  style={{ marginTop: "clamp(16px,7.47648vw,64px)" }}
                >
                  <section className="tab-one">
                    <h5 className="plate-heading">Формирователь десны</h5>
                    <span className="text">
                      Установка формирователя десны SuperLine <br /> <br />{" "}
                      (ИМ-1 )
                    </span>
                    <span className="price">От 7.850₽</span>
                  </section>
                  <section className="tab-two">
                    <span className="text">
                      Установка формирователя десны Strumann <br /> <br />{" "}
                      (ИМ-2)
                    </span>
                    <span className="price">От 6.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Установка формирователя десны NeoDent GM <br /> <br />{" "}
                      (ИМ-3)
                    </span>
                    <span className="price">От 7.850₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Установка формирователя десны <br /> <br /> (ИМ-4){" "}
                    </span>
                    <span className="price last">От 7.850₽</span>
                  </section>
                </div>
                <div
                  className="plate"
                  style={{ marginTop: "clamp(16px,7.47648vw,64px)" }}
                >
                  <section className="tab-one">
                    <h5 className="plate-heading">Синус-лифтинг</h5>
                    <span className="text">
                      Синус-лифтинг (без учета стоимости материалов) -закрытый
                      <br /> <br /> (ИМ--)
                    </span>
                    <span className="price">От 15.750₽</span>
                  </section>
                  <section className="tab-two">
                    <span className="text">
                      Синус-лифтинг (без учета стоимости материалов) -открытый{" "}
                      <br /> <br /> (ИМ--)
                    </span>
                    <span className="price">От 42.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Направленная костная регенерация (HKP) титановой сеткой{" "}
                      <br /> <br /> (ИМ-16.08)
                    </span>
                    <span className="price">От 25.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Подсыпка костной ткани после удаления Bio-Oss <br />{" "}
                      <br /> (ИМ-А16.07 1)
                    </span>
                    <span className="price">От 5.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Костная пластика челюстно-лицевой области с применением
                      биодеградируемых материалов (костно-пластический материал
                      Bio-Oss 0,5 гр.) <br /> <br /> (ИМ-А16.07.041.001.01)
                    </span>
                    <span className="price">От 24.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Костная пластика челюстно-лицевой области с применением
                      биодеградируемых материалов (мембраны Bio-Gide) 1 шт.{" "}
                      <br /> <br /> (ИМ-А16.07.041:001.03)
                    </span>
                    <span className="price">От 30.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Использование хирургического шаблона от 3 зубов <br />{" "}
                      <br /> (ИМ-А16.07.054.008)
                    </span>
                    <span className="price">От 15.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Использование хирургического шаблона от 4 зубов <br />{" "}
                      <br /> (ИМ-А16.07.054.009)
                    </span>
                    <span className="price">От 24.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Переустановка импланта <br /> <br /> (ИМ-А16.07.054.009){" "}
                    </span>
                    <span className="price last">От 7.500₽</span>
                  </section>
                </div>
                <div
                  className="plate"
                  style={{ marginTop: "clamp(16px,7.47648vw,64px)" }}
                >
                  <section className="tab-one">
                    <h5 className="plate-heading">Реконструктивное лечение</h5>
                    <span className="text">
                      Инъекционное введение лекарственных препаратов в
                      челюстно-лицевую область <br /> <br /> (А11.07.011)
                    </span>
                    <span className="price">От 400₽</span>
                  </section>
                  <section className="tab-two">
                    <span className="text">
                      Аппликация лекарственного препарата на слизистую оболочку
                      полости рта <br /> <br /> (А11.07.022)
                    </span>
                    <span className="price">От 550₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Ультразвуковая обработка пародонтального кармана в области
                      зуба с применением лекарственных средств (лечение
                      заболеваний пародонта аппаратом "Vector Para Pro" в
                      области 1 зуба/импланта) <br /> <br />{" "}
                      (А22.07.001,А11.07.010)
                    </span>
                    <span className="price">От 850₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Ультразвуковая обработка пародонтального кармана в области
                      зуба с применением лекарственных средств (лечение
                      заболеваний пародонта аппаратом "Vector Para Pro" 2
                      челюсти) <br /> <br /> (А22.07.001.001,А11.07.010)
                    </span>
                    <span className="price">От 10.500₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Лазерная физиотерапия челюстно-лицевой области (1
                      процедура) <br /> <br /> (А22.07.003)
                    </span>
                    <span className="price">От 370₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Костная пластика челюстно-лицевой области с применением
                      биодеградируемых материалов (мембраны Bio-Gide) 1 шт.{" "}
                      <br /> <br /> (ИМ-А16.07.041:001.03)
                    </span>
                    <span className="price">От 30.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Использование хирургического шаблона от 3 зубов <br />{" "}
                      <br /> (ИМ-А16.07.054.008)
                    </span>
                    <span className="price">От 15.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Использование хирургического шаблона от 4 зубов
                      <br /> <br /> (ИМ-А16.07.054.009)
                    </span>
                    <span className="price">От 24.000₽</span>
                  </section>
                  <section className="tab-three">
                    <span className="text">
                      Переустановка импланта <br /> <br /> (ИМ-А16.07.054.009){" "}
                    </span>
                    <span className="price last">От 7.500₽</span>
                  </section>
                </div>
              </div>
              <div className="services-gallery">
                <h4 className="heading">Работы</h4>
                <div className="divider"></div>
                <ServiceGallery
                  imageSrc={serviceOneMob}
                  imageSrcTwo={serviceTwoMob}
                  imageSrcThree={serviceOneMob}
                  imageSrcFour={serviceFourMob}
                />
              </div>
              <div className="services-gallery">
                <h4 className="heading">Наши специалисты</h4>
                <div className="divider"></div>
                <Gallery
                  imageSrc={doctorOneMob}
                  imageSrcTwo={doctorTwoMob}
                  imageSrcThree={doctorThreeMob}
                  openModal={openModal}
                  imageSrcFour={doctorFourMob}
                  imageSrcFive={doctorFiveMob}
                  imageSrcSix={doctorSixMob}
                  imageSrcSeven={doctorTenMob}
                  imageSrcEight={doctorEightMob}
                  imageSrcNine={doctorElevenMob}
                />
              </div>
              <div className="services-gallery">
                <h4 className="heading">Отзывы</h4>
                <div className="divider"></div>
                <ReviewGallery></ReviewGallery>
                <div className="form-screen" id="form-screen-mob">
                  <section className="content">
                    <div className="form-heading-container">
                      <h3 className="form-heading">Записаться На Прием</h3>
                    </div>
                    <div className="divider"></div>
                    <form action="" className="form">
                      <div className="input-container">
                        <label htmlFor="name" className="la">
                          Имя*
                        </label>
                        <input
                          type="text"
                          required={true}
                          value={fullName}
                          name="fullName"
                          onChange={(event) => setFullName(event.target.value)}
                          className="input-text"
                          placeholder="Иван Иванов"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="phone" className="la">
                          Номер телефона*
                        </label>
                        <input
                          type="tel"
                          value={phoneNumber}
                          onChange={(event) =>
                            setPhoneNumber(event.target.value)
                          }
                          name="phoneNumber"
                          className="input-text"
                          placeholder="+7 (991) 779-39-95"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="service" className="la">
                          Услуга
                        </label>
                        <input
                          type="text"
                          value={serviceName}
                          onChange={(event) =>
                            setServiceName(event.target.value)
                          }
                          name="serviceName"
                          placeholder="Выберите Услугу"
                          className="input-text"
                        />
                      </div>

                      {/* <div className="input-container">
                    <label htmlFor="direction" className="la">
                      Направление
                    </label>
                    <input
                      type="text"
                      name="direction"
                      placeholder="Выберите Направление"
                      className="input-text"
                    />
                  </div> */}
                      <div className="input-container">
                        <label htmlFor="doctor" className="la">
                          Фамилия врача
                        </label>
                        <input
                          type="text"
                          name="doctorName"
                          value={doctorName}
                          onChange={(event) =>
                            setDoctorName(event.target.value)
                          }
                          placeholder="Фамилия врача"
                          className="input-text"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="service" className="la">
                          Дата приема
                        </label>
                        <input
                          type="text"
                          value={bookDate}
                          onChange={(event) => setBookDate(event.target.value)}
                          name="bookDate"
                          placeholder="Дата приема"
                          className="input-text"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="service" className="la">
                          Время приема
                        </label>
                        <input
                          type="text"
                          value={bookTime}
                          onChange={(event) => setBookDate(event.target.value)}
                          name="bookTime"
                          placeholder="Время приема"
                          className="input-text"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="service" className="la">
                          Цель визита
                        </label>
                        <input
                          type="text"
                          value={visitGoal}
                          onChange={(event) => setVisitGoal(event.target.value)}
                          name="visitGoal"
                          placeholder="Время приема"
                          className="input-text"
                        />
                      </div>
                      <div className="input-container">
                        <label htmlFor="comment" className="la">
                          Комментарий
                        </label>
                        <textarea
                          name="comment"
                          value={comment}
                          onChange={(event) => setComment(event.target.value)}
                          className="comment"
                        ></textarea>
                      </div>
                      <div className="checkbox-container">
                        <div className="checkbox-container-2">
                          <MDBCheckbox
                            name="flexCheck"
                            value=""
                            id="flexCheckChecked"
                            label="Ознакомлен с Условиями обработки персональных данных"
                            defaultChecked
                          />
                        </div>
                      </div>
                      <button className="form-button" value="Send">
                        Записаться на прием
                        <FontAwesomeIcon
                          className="icon"
                          icon={faChevronRight}
                          style={{
                            fontSize: "clamp(7px,3.2709599999999996vw,28px",
                            marginLeft: "clamp(4px,1.86912vw,16px)",
                          }}
                        ></FontAwesomeIcon>
                      </button>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </main>
          <main className="services-pc">
            <div className="pc-content">
              <Header
                isMenuPcOpen={isMenuPcOpen}
                openModal={openModal}
                togglePcMenu={togglePcMenu}
              ></Header>
              <div className="upper-content">
                <div className="heading-container">
                  <div className="bread-dots-container">
                    <Link to="/" className="link">
                      Главная
                    </Link>
                    <div className="circle"></div>
                    <Link to="/#services" className="link">
                      Услуги
                    </Link>
                    <div className="circle"></div>
                    <Link to="/services/prosthetics" className="link active">
                      Протезирование
                    </Link>
                  </div>
                  <h1 className="heading" style={{ width: "30%" }}>
                    Имплантация зубов и челюстей в Ивантеевке
                  </h1>
                  <button className="button" onClick={openModal}>
                    <span className="text">Записаться</span>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="icon"
                    ></FontAwesomeIcon>
                  </button>
                </div>
                <img
                  src={prostheticsPc}
                  alt="service"
                  className="service-image"
                ></img>
              </div>
              <div className="services-content">
                <div className="services-list">
                  <span className="text">Содержание</span>
                  <div className="buttons">
                    <ScrollLink
                      to="information"
                      className="button"
                      style={{ width: "110%" }}
                    >
                      Основная информация
                    </ScrollLink>
                    <ScrollLink to="doctors" className="button">
                      Врачи
                    </ScrollLink>
                    <ScrollLink to="prices" className="button">
                      Цена
                    </ScrollLink>
                    <ScrollLink to="works" className="button">
                      Работы
                    </ScrollLink>
                    <ScrollLink to="reviews" className="button">
                      Отзывы
                    </ScrollLink>
                    <ScrollLink
                      to="form-screen"
                      value={"Записаться на прием"}
                      className="button"
                    >
                      Записаться на прием
                    </ScrollLink>
                  </div>
                </div>
                <div className="services-information" id="information">
                  <span className="text">Об услуге</span>
                  <div className="information-one">
                    <h3 className="heading-info">
                      Имплантация ключ в Dental Home
                    </h3>
                    <p className="p-info">
                      Имплантация зуба под ключ в Ивантеевке – это современный и
                      эффективный способ восстановления утраченного зуба. Наша
                      клиника предлагает качественные услуги по вставке зубных
                      имплантов, которые помогут вернуть вам уверенность в своей
                      улыбке.
                      <br />
                      <br />
                      Мы предлагаем различные виды зубных имплантов по доступной
                      цене. Наши специалисты помогут вам выбрать наиболее
                      подходящий вариант, который удовлетворит все ваши
                      потребности.
                      <br />
                      <br />
                      Установка импланта зуба под ключ – это процедура, которая
                      проводится в несколько этапов. Сначала наш специалист
                      проводит осмотр и консультацию, после чего планируется
                      хирургическая операция по установке импланта. Затем
                      происходит период ожидания, когда имплант должен
                      интегрироваться с костью. После этого проводится этап
                      протезирования, когда на имплант устанавливается коронка.
                      <br />
                      <br />
                      Установка импланта зуба под ключ цена зависит от сложности
                      процедуры и используемых материалов. Но мы гарантируем,
                      что наши цены будут доступными и адекватными.
                      <br />
                      <br />
                      Где лучше ставить импланты зубов? Наша клиника Dental Home
                      находится в Ивантеевке и предлагает качественные услуги по
                      установке имплантов зубов в Московской области. Мы
                      используем только самые современные технологии и
                      материалы, чтобы обеспечить максимальный результат.
                      <br />
                      <br />
                      Имплант зуба сразу – это возможность получить новый зуб за
                      один день. Наша клиника предлагает услугу установки
                      импланта зуба сразу после удаления старого зуба. Это
                      позволяет избежать периода ожидания и получить новый зуб
                      максимально быстро.
                      <br />
                      <br />
                      Стоимость импланта зуба – это важный вопрос для многих
                      пациентов. Но мы гарантируем, что наши цены на установку
                      импланта зуба будут доступными и адекватными. Мы
                      предлагаем качественные услуги по установке имплантов
                      зубов по разумной цене.
                      <br />
                      <br />
                      Зубные импланты цена – это важный фактор при выборе
                      клиники для установки имплантов зубов. Наша клиника
                      предлагает качественные услуги по установке имплантов
                      зубов по доступной цене. Мы гарантируем максимальный
                      результат и безболезненную процедуру.
                      <br />
                      <br />
                      Установка импланта зуба – это возможность получить новый
                      зуб, который будет выглядеть и функционировать как
                      настоящий. Наша клиника предлагает качественные услуги по
                      установке импланта зуба под ключ, которые помогут вам
                      вернуть уверенность в своей улыбке. Не откладывайте поход
                      к стоматологу на потом! Запишитесь на консультацию по
                      установке импланта зуба уже сегодня и получите
                      профессиональную помощь в достижении желаемого результата.
                    </p>
                  </div>
                  <div className="price-tab" id="prices">
                    <h4 className="price-heading">
                      Протезирование на имплантах
                    </h4>
                    <div className="prices">
                      <div className="price-row">
                        <span className="text">
                          {""}
                          <a
                            href="https://dental-home.ru/services/prosthetics"
                            className="white"
                            style={{ textDecoration: "underline" }}
                          >
                            {""}
                            Протезирование зуба{" "}
                          </a>{" "}
                          с использованием имплантата временной коронкой +
                          временный абатмент <br /> <br /> (ИМ-А16.07.006.001 )
                        </span>
                        <span className="price">От 8.950₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Единица промежуточной части временного мостовидного
                          протеза с опорой на имплантах <br /> <br />{" "}
                          (ИМ-А16.07.006.002)
                        </span>
                        <span className="price">От 4.750₽</span>
                      </div>
                      <div className="price-row ">
                        <span className="text">
                          Протезирование зуба с использованием имплантата
                          коронкой металлокерамической класса "Эстетик"
                          (трансокклюзионная фиксация) <br /> <br />{" "}
                          (ИМ-А16.07.006.004)
                        </span>
                        <span className="price">От 32.550₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование зуба с использованием имплантата
                          коронкой металлокерамической класса "стандарт"
                          (цементная фиксация) на индивидуальном абатменте{" "}
                          <br /> <br /> (ИМ-А16.07.006.005)
                        </span>
                        <span className="price">От 26.250₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование зуба с использованием имплантата
                          коронкой металлокерамической класса "Премиум"
                          (цементная фиксация) на индивидуальном абатменте{" "}
                          <br /> <br /> (ИМ-А16.07.006.006)
                        </span>
                        <span className="price">От 32.550₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Единица промежуточной части мостовидного протеза
                          класса "Стандарт" (металлокерамика) с опорой на
                          имплантах <br /> <br /> (ИМ-А16.07.006.007)
                        </span>
                        <span className="price">От 17.850₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Единица промежуточной части мостовидного протеза
                          класса "Премиум" (металлокерамика) с опорой на
                          имплантах <br /> <br /> (ИМ-А16.07.006.008)
                        </span>
                        <span className="price">От 21.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование зуба с использованием имплантата
                          коронокой из диоксида циркония класса
                          "Стандарт"(трансокклюзионная фиксация) <br /> <br />{" "}
                          (ИМ-А16.07.006.009)
                        </span>
                        <span className="price">От 33.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование зуба с использованием имплантата
                          коронокой из диоксида циркония класса
                          "Премиум"(трансокклюзионная фиксация) <br /> <br />{" "}
                          (ИМ-А16.07.006.010)
                        </span>
                        <span className="price">От 47.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          {" "}
                          Протезирование зуба с использованием имплантата
                          коронокой из диоксида циркония класса
                          "Стандарт"(трансокклюзионная фиксация) на
                          индивидуальном абатмене <br /> <br />{" "}
                          (ИМ-А16.07.006.011)
                        </span>
                        <span className="price">От 41.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          {" "}
                          Протезирование зуба с использованием имплантата
                          коронокой из диоксида циркония класса
                          "Премиум"(трансокклюзионная фиксация) на
                          индивидуальном абатмене <br /> <br />{" "}
                          (ИМ-А16.07.006.012)
                        </span>
                        <span className="price">От 47.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Единица промежуточной части мостовидного протеза из
                          диоксида циркония класса "Стандарт" с опорой на
                          имплантатах <br /> <br /> (ИМ-А16.07.006.013)
                        </span>
                        <span className="price">От 23.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Единица промежуточной части мостовидного протеза из
                          диоксида циркония класса "Премиум" с опорой на
                          имплантатах <br /> <br /> (ИМ-А16.07.006.014)
                        </span>
                        <span className="price">От 27.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование зуба с использованием имплантата
                          коронкой E-max на титановом основании класса "Премиум"
                          (трансокклюзионная фиксация) <br /> <br />{" "}
                          (ИМ-А16.07.006.015)
                        </span>
                        <span className="price">От 31.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Индивидуальный абатмент из диоксида циркония
                          <br /> <br /> (ИМ-А16.07.006.016)
                        </span>
                        <span className="price">От 15.800₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование полным условно-съемным протезом с
                          опорой на имплантатах (балочная конструкция)
                          мультиюниты оплачиваются отдельно <br /> <br />{" "}
                          (ИМ-А16.07.006.017)
                        </span>
                        <span className="price">От 194.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование полным несъемным протезом с опорой на
                          имплантатах на мультиюнитах (мультиюниты оплачиваются
                          отдельно) <br /> <br /> (ИМ-А16.07.006.018)
                        </span>
                        <span className="price">От 194.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Стоимость мультиюнита (1 единица) <br /> <br />{" "}
                          (ИМ-А16.07.006.019)
                        </span>
                        <span className="price">От 21.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование полным съемным протезом на имплантатах
                          с замковым креплением (люкаторная система замков){" "}
                          <br /> <br /> (ИМ-А16.07.006.020 )
                        </span>
                        <span className="price">От 126.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Протезирование полным съемным протезом на имплантатах
                          на аттачментах <br /> <br /> (ИМ-А16.07.006.021)
                        </span>
                        <span className="price">От 99.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Временный несъемный протез на имплантатах <br />{" "}
                          <br />
                          (ИМ-А16.07.006.22 )
                        </span>
                        <span className="price">От 84.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Замена винта трансокклюзионной фиксации <br /> <br />{" "}
                          (ИМ-А23.07.002.04)
                        </span>
                        <span className="price last">От 550₽</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="price-tab"
                    id="prices"
                    style={{ marginTop: "clamp(16px,1.66656vw,64px)" }}
                  >
                    <h4 className="price-heading">
                      Внутрикостная дентальная имплантация системой
                    </h4>
                    <div className="prices">
                      <div className="price-row">
                        <span className="text">
                          Имплантация системой Dentium SuperLine (Ю.Корея){" "}
                          <br /> <br /> (ИМ-А16.07.054.001)
                        </span>
                        <span className="price">От 41.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Имплантация системой NeoDent GM (Бразилия) <br />{" "}
                          <br /> (ИМ-А16.07.054.002)
                        </span>
                        <span className="price">От 41.000₽</span>
                      </div>
                      <div className="price-row ">
                        <span className="text">
                          Имплантация системой Nobel Biocare (Швейцария) <br />{" "}
                          <br /> (ИМ-А16.07.054.003)
                        </span>
                        <span className="price">От 73.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Миниимплант (ортовинт) <br /> <br />{" "}
                          (ИМ-А16.07.054.004){" "}
                        </span>
                        <span className="price last">От 12.600₽</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="price-tab"
                    id="prices"
                    style={{ marginTop: "clamp(16px,1.66656vw,64px)" }}
                  >
                    <h4 className="price-heading">Формирователь десны</h4>
                    <div className="prices">
                      <div className="price-row">
                        <span className="text">
                          Установка формирователя десны SuperLine <br /> <br />{" "}
                          (ИМ-1)
                        </span>
                        <span className="price">От 7.850₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Установка формирователя десны Strumann <br /> <br />{" "}
                          (ИМ-2)
                        </span>
                        <span className="price">От 6.000₽</span>
                      </div>
                      <div className="price-row ">
                        <span className="text">
                          Установка формирователя десны NeoDent GM <br /> <br />{" "}
                          (ИМ-3)
                        </span>
                        <span className="price">От 7.850₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Установка формирователя десны <br /> <br /> (ИМ-4)
                        </span>
                        <span className="price last">От 7.850₽</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="price-tab"
                    id="prices"
                    style={{ marginTop: "clamp(16px,1.66656vw,64px)" }}
                  >
                    <h4 className="price-heading">Реконструктивное лечение</h4>
                    <div className="prices">
                      <div className="price-row">
                        <span className="text">
                          Инъекционное введение лекарственных препаратов в
                          челюстно-лицевую область <br /> <br /> (А11.07.011)
                        </span>
                        <span className="price">От 400₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Аппликация лекарственного препарата на слизистую
                          оболочку полости рта <br /> <br /> (А11.07.022)
                        </span>
                        <span className="price">От 580₽</span>
                      </div>
                      <div className="price-row ">
                        <span className="text">
                          Ультразвуковая обработка пародонтального кармана в
                          области зуба с применением лекарственных средств
                          (лечение заболеваний пародонта аппаратом "Vector Para
                          Pro" в области 1 зуба/импланта) <br /> <br />{" "}
                          (А22.07.001,А11.07.010)
                        </span>
                        <span className="price">От 850₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Ультразвуковая обработка пародонтального кармана в
                          области зуба с применением лекарственных средств
                          (лечение заболеваний пародонта аппаратом "Vector Para
                          Pro" 2 челюсти) <br /> <br />{" "}
                          (А22.07.001.001,А11.07.010)
                        </span>
                        <span className="price">От 10.500₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Лазерная физиотерапия челюстно-лицевой области (1
                          процедура) <br /> <br /> (А22.07.003)
                        </span>
                        <span className="price">От 370₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Костная пластика челюстно-лицевой области с
                          применением биодеградируемых материалов (мембраны
                          Bio-Gide) 1 шт. <br /> <br /> (ИМ-А16.07.041:001.03)
                        </span>
                        <span className="price">От 30.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Использование хирургического шаблона от 3 зубов <br />{" "}
                          <br /> (ИМ-А16.07.054.008)
                        </span>
                        <span className="price">От 15.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Использование хирургического шаблона от 4 зубов <br />{" "}
                          <br /> (ИМ-А16.07.054.009)
                        </span>
                        <span className="price">От 24.000₽</span>
                      </div>
                      <div className="price-row">
                        <span className="text">
                          Переустановка импланта <br /> <br />{" "}
                          (ИМ-А16.07.054.009){" "}
                        </span>
                        <span className="price last">От 7.500₽</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="gallery"
                id="works"
                style={{
                  borderTop: "1px solid #202637",
                  marginTop: "clamp(32px,3.33312vw,128px)",
                }}
              >
                <PcServiceGallery
                  imageSrc={serviceOnePc}
                  imageSrc2={serviceTwoPc}
                  imageSrc3={ServiceThreePc}
                  imageSrc4={ServiceFourPc}
                ></PcServiceGallery>
              </div>
              <div
                className="gallery"
                id="doctors"
                style={{
                  borderTop: "1px solid #202637",
                }}
              >
                <PcGallery
                  imageSrc={doctorOnePc}
                  imageSrc2={doctorTwoPc}
                  imageSrc3={doctorThreePc}
                  imageSrc4={doctorFourPc}
                  imageSrc5={doctorFivePc}
                  imageSrc6={doctorSixPc}
                  imageSrc7={doctorTenPc}
                  imageSrc8={doctorSevenPc}
                  imageSrc9={doctorNinePc}
                />
                <div
                  className="gallery"
                  id="reviews"
                  style={{
                    borderTop: "1px solid #202637",
                    marginTop: "clamp(32px,3.33312vw,128px)",
                  }}
                >
                  <ReviewGalleryDesktop />
                </div>
                <div
                  className="form-pc-screen"
                  id="form-screen"
                  ref={form}
                  style={{ marginBottom: "clamp(64px,6.66624vw,256px)" }}
                  onSubmit={sendEmail}
                >
                  <section className="content">
                    <div className="heading-container">
                      <h5 className="heading">Записаться На Прием</h5>
                    </div>
                    <div className="divider"></div>
                    <form action="" className="form-content">
                      <div className="row-c">
                        <div className="input-container">
                          <span className="text">Имя*</span>
                          <input
                            type="text"
                            className="input-c"
                            required={true}
                            value={fullName}
                            name="fullName"
                            onChange={(event) =>
                              setFullName(event.target.value)
                            }
                            placeholder="Иван Иванов"
                          />
                        </div>
                        <div className="input-container">
                          <span className="text">Телефон*</span>
                          <input
                            type="text"
                            className="input-c"
                            required={true}
                            value={phoneNumber}
                            name="phoneNumber"
                            onChange={(event) =>
                              setPhoneNumber(event.target.value)
                            }
                            placeholder="+7 (991) 779-39-95"
                          />
                        </div>
                      </div>
                      <div className="row-c">
                        <div className="input-container services">
                          <span className="text">Услуга</span>
                          <input
                            type="text"
                            placeholder="Выберите Услугу"
                            required={true}
                            value={serviceName}
                            name="serviceName"
                            onChange={(event) =>
                              setServiceName(event.target.value)
                            }
                            className="input-s"
                          />
                        </div>
                      </div>
                      <div className="row-c"></div>
                      <div className="row-c">
                        <div className="input-container services">
                          <span className="text">Фамилия врача</span>
                          <input
                            type="text"
                            placeholder="Фамилия врача"
                            required={true}
                            value={doctorName}
                            name="doctorName"
                            onChange={(event) =>
                              setDoctorName(event.target.value)
                            }
                            className="input-s"
                          />
                        </div>
                      </div>
                      <div className="row-c">
                        <div className="input-container services">
                          <span className="text">Дата приема</span>
                          <input
                            type="text"
                            placeholder="Дата приема"
                            // ! поменять на bookDate
                            value={bookDate}
                            name="bookDate"
                            onChange={(event) =>
                              setBookDate(event.target.value)
                            }
                            className="input-s"
                          />
                        </div>
                      </div>
                      <div className="row-c">
                        <div className="input-container services">
                          <span className="text">Время приема</span>
                          <input
                            type="text"
                            placeholder="Время приема"
                            value={bookTime}
                            name="bookTime"
                            onChange={(event) =>
                              setBookTime(event.target.value)
                            }
                            className="input-s"
                          />
                        </div>
                      </div>
                      <div className="row-c">
                        <div className="input-container services">
                          <span className="text">Цель визита</span>
                          <input
                            type="text"
                            placeholder="Цель визита"
                            value={visitGoal}
                            name="visitGoal"
                            onChange={(event) =>
                              setVisitGoal(event.target.value)
                            }
                            className="input-s"
                          />
                        </div>
                      </div>
                      <div className="row-c">
                        <div className="input-container services">
                          <span className="text">Комментарий</span>
                          <textarea
                            className="comment"
                            name="comment"
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="checkbox-container">
                        <div className="check-row">
                          <input type="checkbox" className="checkbox" />
                          <span className="text">
                            Ознакомлен с Условиями обработки персональных данных
                          </span>
                        </div>
                        <button className="golden-btn" value="Send">
                          Записаться на прием
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="icon"
                            style={{
                              fontSize: "clamp(6px,0.62496vw,24px)",
                              marginLeft: "clamp(4px,0.41664vw,16px)",
                            }}
                          ></FontAwesomeIcon>
                        </button>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </main>
          <Footer />
          <Popup
            open={open}
            closeOnDocumentClick
            onClose={closeModal}
            modal
            nested
            className="popup-container"
            position="center center"
          >
            <div className="modal">
              <img
                className="modal-img"
                src={modalImage}
                alt="modal-picture"
              ></img>
              <FontAwesomeIcon
                icon={faClose}
                onClick={closeModal}
                className="gold-cross"
              ></FontAwesomeIcon>
              <div className="modal-content">
                <img className="logo" src={logoMobile} alt="logotype"></img>
                <span className="text">Хотите получить консультацию?</span>
                <span className="additional-text">
                  Оставьте свой номер и мы перезвоним вам
                </span>
                <form className="input-container" onSubmit={sendPhoneRequest}>
                  <label htmlFor="phone-number-input" className="label">
                    Ваш номер телефона*
                  </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className="phone-number-input"
                    placeholder="+7 (925) 222-90-22"
                    required={true}
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    style={{ textAlign: "center" }}
                    id=""
                  />
                  <button className="phone-btn" value="Send">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="icon"
                    ></FontAwesomeIcon>
                    Хорошо жду звонка
                  </button>
                </form>
              </div>
            </div>
          </Popup>
          <Popup
            open={thankYou}
            closeOnDocumentClick
            onClose={closeModal}
            modal
            nested
            className="popup-container"
            position="center center"
            overlayStyle={{ background: "rgba(0,0,0,0.7)" }}
          >
            <div className="modal-thanks">
              <FontAwesomeIcon
                icon={faClose}
                onClick={closeModal}
                className="gold-cross"
              ></FontAwesomeIcon>
              <div className="modl-content">
                <img className="logo" src={logoMobile} alt="logotype"></img>
                <span className="text">Спасибо за вашу заявку</span>
                <span className="additional-text">
                  В скором времени мы свяжемся с вами
                </span>
              </div>
            </div>
          </Popup>
        </div>
      )}
    </div>
  );
};

export default ImplantsPage;
